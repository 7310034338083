<template>
  <notification-item gacha-win :message="message" @delete-item="deleteItem" @close="close">
    <template #title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p v-if="info">{{ $t(['_', messageKey, 'content'], { nickname }) }}</p>
    <template v-slot:buttons>
      <button @click="goLink">{{ $t(['_', messageKey, 'button']) }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'LotteryWinItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.GACHA_WIN',
  props: {
    message: Specific,
  },
  computed: {
    /** @type {{ formLink: string, messageKey: string }} */
    info() {
      return this.message?.data;
    },
    nickname() {
      return this.$store.state.auth.authInfo.nickname;
    },
    messageKey() {
      return this.info?.messageKey;
    },
  },
  methods: {
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
    goLink() {
      if (this.messageKey === 'land') this.routeWcgPath('/land');
      else window.open(this.info.formLink);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[gacha-win] {
  .body p { .pre-line }
}
</style>
